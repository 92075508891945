import {
  isEmpty,
  isNumber,
  sortBy,
  upperFirst,
  merge,
  keyBy,
  values,
  flatten,
  isNil,
  orderBy,
} from "lodash-es";
import {
  differenceInHours,
  format,
  isAfter,
  isBefore,
  parseISO,
  addMinutes,
  isSameDay,
  endOfDay,
  compareAsc,
  isSameYear,
  roundToNearestMinutes,
} from "date-fns";
import { formatToTimeZone, convertToTimeZone } from "date-fns-timezone";
import {
  FilterType,
  ToggleFilterItem,
  DateRangeType,
  FilterItem,
  RadioButtonsFilterItem,
  CustomReferenceAreaProps,
  IntervalType,
} from "ui-core";
import {
  dateFormatByResolution,
  getAssetTypeParams,
  mapResolutionToIntervalLabel,
  marketEventCategoryMap,
  marketEventStatusName,
} from "./dataMapping";
import {
  AssetV1Fragment,
  AssetV2Fragment,
  BasicAssetSummaryFragment,
  MarketRevenueReadingFragment,
  Maybe,
  ReadingsAggregateV2Fragment,
  ReadingRecordFragment,
  ReadingV2Fragment,
  MarketEventFragment,
} from "src/graphql/code";
import { v1paramTypes, convertV1ReadingsToV2Format } from "./v2ConfigUtils";
import CustomEventWindow from "src/components/general/CustomEventWindow";
import CustomErcotEventAnimatedWindow from "src/components/general/CustomErcotEventAnimatedWindow";
import { EnergyAsset } from "src/components/ui-core/types/assets";
import { getLocaleNumber } from "./utils";

export const defaultLocale = "en-US";
export const defaultTimezone = "UTC";
// parse ISO timestamp, convert it to original local time and format
// TODO: temp solution - ignore timeshift in the ISO timestamp - only for cases where
//       we don't know the target timezone (multiple buildings in one chart)
export const formatIsoToLocalTime = (isoDate: string, resolution: string) => {
  const ISOWithoutTimeshift = isoDate.slice(0, 19);
  return formatToTimeZone(
    parseISO(ISOWithoutTimeshift),
    dateFormatByResolution(resolution),
    {
      timeZone:
        Intl.DateTimeFormat().resolvedOptions().timeZone || defaultTimezone,
    }
  );
};

// parses the date from given ISO string and converts to target timezone
export const formatIsoToTimezone = (
  isoDate: string,
  timezoneName: string,
  resolution: string,
  dateTimeFormats?: {
    dateFormat?: string;
    timeFormat?: string;
  },
  showTimeOnlyForUnderDayResolution?: boolean
) => {
  return timezoneName.length && isoDate
    ? formatToTimeZone(
        parseISO(isoDate),
        dateFormatByResolution(
          resolution,
          dateTimeFormats,
          showTimeOnlyForUnderDayResolution
        ),
        {
          timeZone: timezoneName,
        }
      )
    : "Invalid Date";
};

export const formatDateToTimezone = (
  date: Date,
  // TODO: be more specific
  timezoneName: string,
  resolution: string,
  dateTimeFormats?: {
    dateFormat?: string;
    timeFormat?: string;
  },
  showTimeOnlyForUnderDayResolution?: boolean
) => {
  return timezoneName.length && date
    ? formatToTimeZone(
        date,
        dateFormatByResolution(
          resolution,
          dateTimeFormats,
          showTimeOnlyForUnderDayResolution
        ),
        {
          timeZone: timezoneName,
        }
      )
    : "Invalid Date";
};

// parses the date and converts to target timezone
export function formatDateWithTimezone(
  date: Date,
  timezoneName: string,
  resolution: string,
  dateTimeFormats?: {
    dateFormat?: string;
    timeFormat?: string;
  },
  showTimeOnlyForUnderDayResolution?: boolean
) {
  return timezoneName.length
    ? formatToTimeZone(
        date,
        dateFormatByResolution(
          resolution,
          dateTimeFormats,
          showTimeOnlyForUnderDayResolution
        ),
        {
          timeZone: timezoneName,
        }
      )
    : "Invalid Date";
}

// from a given date and name of the target timezone - creates an ISO string
// with correct timeshift information (not the default UTC 'Z')
export function formatToIsoWithTimezone(
  date: Date | null,
  timezoneName: string
) {
  return date instanceof Date && timezoneName.length
    ? `${format(date, "yyyy-MM-dd")}T${format(
        date,
        "HH:mm:ss"
      )}${formatToTimeZone(date, "Z", {
        timeZone: timezoneName,
      })}`
    : "Invalid Date";
}

// from a given date creates an ISO string with no timezone info
export function formatToIsoWithoutTimezone(date: Date) {
  return date instanceof Date
    ? `${format(date, "yyyy-MM-dd")}T${format(date, "HH:mm:ss")}`
    : "Invalid Date";
}

export function dropTimezoneInfoFromISO(isoDate: string) {
  return isoDate && isoDate.length
    ? isoDate.replace(/([\d-]+T[\d:]+)(.*)/, "$1")
    : isoDate;
}

/* takes two ISO timestamps (start, end) and formats them into an interval
   form (startDate - endDate)
 */
export function formatStartEndInterval(
  isoStartDate: string,
  isoEndDate: string | undefined,
  timezoneName: string,
  dateTimeFormats: {
    dateFormat: string;
    timeFormat: string;
  }
): string {
  /* note: date formats are different for date-fns and date-fns-timezone. Difference in uppercase in some cases.
     this is to unify them.
  */
  const normalizedDateFormat = dateTimeFormats.dateFormat.toUpperCase();

  const startDateFormatted = {
    date: formatToTimeZone(
      new Date(parseISO(isoStartDate).getTime()),
      normalizedDateFormat,
      {
        timeZone: timezoneName,
      }
    ),
    time: formatToTimeZone(
      new Date(parseISO(isoStartDate).getTime()),
      dateTimeFormats.timeFormat,
      {
        timeZone: timezoneName,
      }
    ),
  };
  const endDateFormatted = isoEndDate && {
    date: formatToTimeZone(
      new Date(parseISO(isoEndDate).getTime()),
      normalizedDateFormat,
      {
        timeZone: timezoneName,
      }
    ),
    time: formatToTimeZone(
      new Date(parseISO(isoEndDate).getTime()),
      dateTimeFormats.timeFormat,
      {
        timeZone: timezoneName,
      }
    ),
  };

  if (endDateFormatted) {
    return `${startDateFormatted.date}\n${startDateFormatted.time} -\n${endDateFormatted.date}\n${endDateFormatted.time}`;
  }

  return `${startDateFormatted.date}\n${startDateFormatted.time} -\nTo be announced`;
}

/**
 * from a list of sites, get aggregated sum of all asset types in form of
 * {
 *   assetId1: #ofAssets,
 *   assetId2: #ofAssets,
 *   ...
 * }
 */

function assetLabelsCompareFn(assetTypeA: string, assetTypeB: string) {
  let labelA = getAssetTypeParams(assetTypeA).label || assetTypeA;
  let labelB = getAssetTypeParams(assetTypeB).label || assetTypeB;

  if (labelA < labelB) return -1;
  else if (labelA > labelB) return 1;
  else return 0;
}

export function sortAssetsByLabels(
  assets:
    | Maybe<
        ({
          __typename?: "AssetSummary" | undefined;
        } & BasicAssetSummaryFragment)[]
      >
    | undefined
) {
  return Array.isArray(assets) && !isEmpty(assets)
    ? assets
        .map((asset) => asset.assetType as EnergyAsset)
        .sort(assetLabelsCompareFn)
    : [];
}

// generate Toggle button items for Search filter view
export function createFilterButtonItems(
  onChangeHandler: (key: string, value: string) => void
): RadioButtonsFilterItem[] {
  return [
    {
      groupId: "filter",
      key: "allFavoriteToggle",
      type: FilterType.RADIOBUTTONS,
      sortIndex: 1,
      defaultKey: "all",
      buttons: [
        {
          key: "all",
          text: "All",
          icon: "grid",
        },
        {
          key: "favorite",
          text: "Favorite",
          icon: "star",
        },
      ],
      onChange: onChangeHandler,
    },
  ];
}

// from list of assets, generate filter/toggles for SearchFilterView
export function createAssetFilterToggles(
  assets: {
    id: number;
    alias: string;
    label: string;
    icon: string;
  }[],
  onChangeHandler: (key: string, value: boolean) => void
): FilterItem[] {
  return Array.isArray(assets) && !isEmpty(assets)
    ? assets.map((asset) => {
        return {
          groupId: "assets",
          key: asset.alias,
          type: FilterType.TOGGLE as FilterType.TOGGLE,
          label: asset.label,
          icon: asset.alias,
          onChange: onChangeHandler,
        } as ToggleFilterItem;
      })
    : [];
}

// when data is indexed by already formatted timestamp, convert it to desired format for chart
export function formatTimestampedDataForChart(
  dataByTimestamp: Record<string, unknown>,
  dataKey: string,
  datasetName: string
) {
  return dataByTimestamp
    ? Object.keys(dataByTimestamp)
        .sort()
        .map((timestamp) => {
          return {
            [dataKey]: timestamp,
            [datasetName]: dataByTimestamp[timestamp],
          };
        })
    : [];
}

// when data is indexed by raw timestamp, convert it to target timezone and use as chart key
export function convertTimestampsAndFormatForChart(
  dataByTimestamp: Record<string, string | number>,
  dataKey: string,
  datasetName: string
) {
  return dataByTimestamp
    ? Object.keys(dataByTimestamp)
        .sort()
        .map((timestamp) => {
          return {
            [dataKey]: parseISO(timestamp).getTime(),
            timestampNumeric: parseISO(timestamp).getTime(), // TODO only for ordering; maybe not needed later
            [datasetName]: dataByTimestamp[timestamp],
          };
        })
    : [];
}

export function formatAssetReadings(
  assetReadings: Record<string, string | number>[] | undefined | null,
  sourceDataKey: string,
  outputDataKey: string,
  outputDatasetName: string
) {
  let dataByTimestamp: Record<string, string | number> = {};

  Array.isArray(assetReadings) &&
    assetReadings.length &&
    assetReadings.forEach((reading) => {
      dataByTimestamp[reading.timestamp] = reading[sourceDataKey] as
        | string
        | number;
    });

  const result = convertTimestampsAndFormatForChart(
    dataByTimestamp,
    outputDataKey,
    outputDatasetName
  );

  return result;
}

// NOTE: currently not used, only in tests - so it's not typed
export function getMasterAsset(assets) {
  if (
    Array.isArray(assets) &&
    assets.length &&
    assets[0].hasOwnProperty("isMainLoad")
  ) {
    const result = assets.find((asset) => asset.isMainLoad);
    return result ? result : null;
  }
  return null;
}

export function valueFormatter(locale: string = "en-US") {
  return new Intl.NumberFormat(locale, { style: "decimal" });
}

// NOTE: currently not used, only in tests - so it's not typed
// from list of assets, pick only 'load', find the master one and get his { id; last reading value }
// or null if it was impossible to pick master load asset ot he has no readings
export function getMasterLoadLastData(assets) {
  // @ts-expect-error
  const masterLoadAsset = getMasterAsset(assets, "load");
  return masterLoadAsset &&
    masterLoadAsset.readings &&
    masterLoadAsset.readings.length
    ? {
        value:
          masterLoadAsset.readings[masterLoadAsset.readings.length - 1]
            .cumulativeEnergy,
        masterLoadId: masterLoadAsset.assetId,
      }
    : null;
}

// NOTE: currently not used, only in tests
// make sure that specific item will be at the beginning of the array
export function startArrayWithItem(items: any[], firstItem: any) {
  return items && items.length
    ? sortBy(items, (item) => (item === firstItem ? 0 : 1))
    : [];
}

export function formatAssetCapacity(
  assetsOrAssetSummarries:
    | Pick<
        BasicAssetSummaryFragment,
        | "assetType"
        | "totalInstalledEnergyCapacity"
        | "totalInstalledPowerCapacity"
      >[]
    | null
    | undefined,
  isSummary = true
) {
  const assetCapacity: Record<
    string,
    { energyCapacity: number; powerCapacity: number }
  > = {};

  if (assetsOrAssetSummarries && assetsOrAssetSummarries.length) {
    assetsOrAssetSummarries.forEach((assetOrAssetSummarry) => {
      let {
        assetType,
        totalInstalledEnergyCapacity,
        totalInstalledPowerCapacity,
        // TODO: check with Kate - this is only used for asset summaries now and
        // they don't have these attributes
        // @ts-expect-error
        installedEnergyCapacity,
        // @ts-expect-error
        installedPowerCapacity,
      } = assetOrAssetSummarry;
      assetCapacity[assetType] = {
        energyCapacity: isSummary
          ? totalInstalledEnergyCapacity
          : installedEnergyCapacity,
        powerCapacity: isSummary
          ? totalInstalledPowerCapacity
          : installedPowerCapacity,
      };
    });
  }

  return assetCapacity;
}

export const getChartStatistics = (
  // TODO: sync with Kate and specify
  aggregateValues: any,
  units: string | undefined,
  dataKey: string,
  dateRange: DateRangeType,
  currentValue: number = 0,
  mapIntervalTypeToResolution: (
    dateRange: DateRangeType
  ) => "5min" | "1day" | "1month" | "1hour",
  includeCumulative = false,
  locale: string = defaultLocale
) => {
  if (
    !aggregateValues ||
    isEmpty(aggregateValues) ||
    typeof mapIntervalTypeToResolution !== "function"
  )
    return [];

  const aggregations = [
    {
      key: "average",
      label: "Average",
    },
    {
      key: "minimum",
      label: "Min",
    },
    {
      key: "maximum",
      label: "Max",
    },
    ...(includeCumulative
      ? [
          {
            key: "sum",
            label: "Cumulative",
          },
        ]
      : []),
  ];
  const statistics: {
    key: string;
    title: string;
    unit?: string;
    label: string;
  }[] = [];
  aggregations.forEach((aggregation) => {
    const aggregatePropertyName = `${aggregation.key}${upperFirst(dataKey)}`;

    statistics.push({
      key: aggregation.key,
      title:
        aggregateValues &&
        aggregateValues.hasOwnProperty(aggregatePropertyName) &&
        String(
          isNumber(aggregateValues[aggregatePropertyName])
            ? getLocaleNumber(locale, aggregateValues[aggregatePropertyName])
            : 0
        ),
      unit: units,
      label:
        aggregation.key === "average"
          ? `${aggregation.label} per ${mapResolutionToIntervalLabel(
              mapIntervalTypeToResolution(dateRange)
            )}`
          : aggregation.label,
    });
  });

  if (dateRange.intervalType === "Live") {
    statistics.push({
      key: "current",
      title: String(
        isNumber(currentValue) ? getLocaleNumber(locale, currentValue) : 0
      ),
      unit: units,
      label: "Current",
    });
  }

  return statistics;
};

/** Takes 2 arrays of objects - both should have 'key' property.
 *  Result is one array with merged objects - merged those that have the same 'key'
 *  Example: array1: [{ timestamp: "2020/10", value: 10 }, { timestamp: "2020/11", value: 20 }]
 *           array2: [{ timestamp: "2020/09", total: 45 }, { timestamp: "2020/10", total: 23 }]
 *        => result: [{ timestamp: "2020/09", total: 45 }
 *                    { timestamp: "2020/10", value: 10, total: 23 },
 *                    { timestamp: "2020/11", value: 20 },
 */
export function mergeArraysOfObjectsByKey(
  array1: any[],
  array2: any[],
  key: any
) {
  var merged = merge(keyBy(array1, key), keyBy(array2, key));
  // !!! values() doesn't guarantee the final order
  return orderBy(values(merged), "timestampNumeric");
}

/** Same as mergeArraysOfObjectsByKey, but will merge multiple arrays at once
 */
export function mergeAllArraysOfObjectsIntoOne(
  arrayOfReadings: Record<string, any>[][],
  key: string
) {
  if (arrayOfReadings && !isEmpty(arrayOfReadings)) {
    return arrayOfReadings.reduce(
      (accMerged, readings) =>
        mergeArraysOfObjectsByKey(accMerged, readings, key),
      []
    );
  }
  return [];
}

/**
 * From the list if market revenue readings (possibly multiple different markets),
 * generate data for revenue chart: revenue-per-month, values from the same month
 * stacked onto each other
 */
export function formatRevenueChartData(
  monthlyRevenueData: ({
    __typename?: "MarketRevenueReading" | undefined;
  } & MarketRevenueReadingFragment)[],
  timezone: string
) {
  const revenuesByMonths: Array<Record<string, any>> = Array(12)
    .fill({})
    .map((_, index) => ({ name: index }));

  monthlyRevenueData.forEach((revenueReading) => {
    const monthNumber = revenueReading?.timestamp
      ? convertToTimeZone(new Date(revenueReading.timestamp), {
          timeZone: timezone,
        }).getMonth()
      : undefined;

    if (monthNumber) {
      revenuesByMonths[monthNumber] = {
        ...revenuesByMonths[monthNumber],
        [revenueReading?.marketName]: revenueReading?.revenue,
        name: monthNumber,
      };
    }
  });

  return revenuesByMonths;
}

/**
 * Takes list of assets, list of readings and the old v1 assets with readings
 * (contain power and energy data) and combines them all into one array
 * of assets + readings.
 * The older v1 data is re-formatted into new structure
 *    (asset > paramTypes > measurementParams > records)
 */

export function combineAssetsWithReadings(
  assets: ({
    __typename?: "AssetV2" | undefined;
  } & AssetV2Fragment)[],
  v2Readings: ({
    __typename?: "ReadingV2" | undefined;
  } & ReadingV2Fragment)[],
  v1AssetsWithReadings: ({
    __typename?: "AssetV1" | undefined;
  } & AssetV1Fragment)[]
) {
  if (isEmpty(assets) || (isEmpty(v2Readings) && isEmpty(v1AssetsWithReadings)))
    return [];

  return assets.map((asset) => {
    const { parameterTypes, ...rest } = asset;

    const assetReadings = parameterTypes.map((parameterType) => {
      const {
        measurementParameters,
        name,
        codeName,
        unit,
        conversionConstant,
      } = parameterType;

      const parameterTypeReadings = flatten(
        measurementParameters.map((measurementParameter) => {
          // find corresponding list of readings for a particular measurementParam in v2Readings
          // array, or in v1AssetsWithReadings - in case of power_v1 and energy_v1
          if (v1paramTypes.has(measurementParameter.codeName)) {
            // power_v1 and energy_v1 from v1 asset readings
            // take assetId, find that asset in v1Readings list and extract measurements out of there
            const v1Asset = v1AssetsWithReadings?.find(
              (v1Asset) => v1Asset.assetId === asset.assetId
            );
            return v1Asset
              ? convertV1ReadingsToV2Format(
                  v1Asset,
                  measurementParameter.codeName
                )
              : [];
          } else if (!isEmpty(v2Readings)) {
            // all other readings from v2 api
            return v2Readings.reduce(
              (assetReadingsAcc, reading) => {
                if (measurementParameter.id === reading.id) {
                  return [
                    ...assetReadingsAcc,
                    {
                      aggregate: reading.aggregate,
                      measurementParameterName: measurementParameter.name,
                      measurementParameterCodeName:
                        measurementParameter.codeName,
                      records: reading.records,
                    },
                  ];
                }

                return assetReadingsAcc;
              },
              [] as {
                aggregate: ({
                  __typename?: "ReadingsAggregateV2" | undefined;
                } & ReadingsAggregateV2Fragment)[];
                measurementParameterName: string;
                measurementParameterCodeName: string;
                records: ({
                  __typename?: "ReadingRecord" | undefined;
                } & ReadingRecordFragment)[];
              }[]
            );
          } else {
            return [];
          }
        })
      );

      const { sumAverage, maximum, minimum, nonEmptyAggregateCount } =
        parameterTypeReadings.reduce(
          (acc, curr) => {
            // NOTE: aggregate comes in an array because of possible aggregate_by condition
            // but here there will be always only one
            const currAggregate = curr.aggregate[0];

            if (isEmpty(currAggregate)) return acc;

            return {
              maximum:
                acc.maximum !== null
                  ? currAggregate.maximum > acc.maximum
                    ? currAggregate.maximum
                    : acc.maximum
                  : currAggregate.maximum,
              minimum:
                acc.minimum !== null
                  ? currAggregate.minimum < acc.minimum
                    ? currAggregate.minimum
                    : acc.minimum
                  : currAggregate.minimum,
              sumAverage:
                acc.sumAverage !== null
                  ? acc.sumAverage + currAggregate.average
                  : currAggregate.average,
              nonEmptyAggregateCount: acc.nonEmptyAggregateCount + 1,
            };
          },
          {
            maximum: null,
            minimum: null,
            sumAverage: null,
            nonEmptyAggregateCount: 0,
          } as {
            maximum: number | null;
            minimum: number | null;
            sumAverage: number | null;
            nonEmptyAggregateCount: number;
          }
        );

      return {
        paramTypeName: name,
        paramTypeCodeName: codeName,
        parameterTypeUnit: unit,
        conversionConstant,
        aggregate: {
          average: !isNil(sumAverage)
            ? (sumAverage / nonEmptyAggregateCount).toFixed(2)
            : null,
          maximum,
          minimum,
        },
        readings: parameterTypeReadings,
      };
    });

    return {
      ...rest,
      readings: assetReadings,
    };
  });
}

// TODO: TEMP solution for connected lines outside of reference area for future events:
//       proper solution needs different implementation of chart layers
/**
 * Takes the list of market events (their start-end dates) and if there's more than one,
 * it will generate a dummy empty data point between them - in each "gap".
 * To keep lines disconnected in case there are no power reading in the future
 */
export function generateDummyDatapointsBetweenEvents(
  events: ({
    __typename?: "MarketEvent" | undefined;
  } & MarketEventFragment)[],
  marketId: string,
  queryEndDate: Date
) {
  if (marketId) {
    events = events.filter((e) => e.market === marketId);
  }
  // flag to know that we removed an event which has end/expected end time in the future (after selected time period)
  let futureDateRemoved = false;

  const eventsWithEndTime = events.filter((event) => {
    const eventEndTime = event.endTime || event.expectedEndTime;
    if (eventEndTime) {
      const eventEndDate = parseISO(eventEndTime);
      // remove all dates which are after the shown period in the chart, as they would prolong the x axis. Remember if any such a date is removed
      const isEventEndAfterQueryEnd = isAfter(eventEndDate, queryEndDate);
      if (!futureDateRemoved && isEventEndAfterQueryEnd) {
        futureDateRemoved = true;
      }
      return !isEventEndAfterQueryEnd;
    }
    return false;
  });

  if (
    (eventsWithEndTime && eventsWithEndTime.length > 1) ||
    (eventsWithEndTime?.length === 1 && futureDateRemoved)
  ) {
    // take the list of all event end-times, order it
    // for all except the last: add entry 5 minutes after the event ended
    const endTimes = eventsWithEndTime.map((event) => {
      if (event.endTime) {
        return parseISO(event.endTime);
      } else if (event.expectedEndTime) {
        return parseISO(event.expectedEndTime);
      }
      return endOfDay(parseISO(event.startTime));
    });

    if (futureDateRemoved) {
      endTimes.push(queryEndDate);
    }

    const endTimesOrdered = endTimes.sort((a: Date, b: Date) =>
      compareAsc(a, b)
    );

    endTimesOrdered.pop();

    const dummyRecords = endTimesOrdered.map((endTime) => {
      const timestamp = roundToNearestMinutes(addMinutes(endTime, 5), {
        nearestTo: 5,
        roundingMethod: "floor",
      }).getTime();

      return {
        timestampNumeric: timestamp,
        timestamp: timestamp,
      };
    });
    return dummyRecords;
  }
  return [];
}

/**
 * Takes the list of market events (their start-end dates) and formats them as chart props
 * so the event can be displayed as a "reference area"
 */
export function formatEventWindowIntoChartProps(
  events: ({
    __typename?: "MarketEvent" | undefined;
  } & MarketEventFragment)[],
  queryParams: { start: string; end: string; resolution: string },
  options: { dateFormat?: string; timeFormat?: string; timezone: string }
) {
  if (events && !isEmpty(events)) {
    const dateFormat = options?.dateFormat ?? "M/D/YYYY";
    const timeFormat = options?.timeFormat ?? "h:mm A";

    let referenceAreaProps: CustomReferenceAreaProps[] = [];

    events.forEach((event) => {
      const startTime = parseISO(event.startTime);

      const endTime = event.endTime ? parseISO(event.endTime) : undefined;

      const expectedEndTime = event.expectedEndTime
        ? parseISO(event.expectedEndTime)
        : undefined;

      const eventStartsBeforeRange = isBefore(
        startTime,
        parseISO(queryParams.start)
      );
      const eventEndsAfterRange = endTime
        ? isAfter(endTime, parseISO(queryParams.end))
        : true;

      const expextedEndAfterRange = expectedEndTime
        ? isAfter(expectedEndTime, parseISO(queryParams.end))
        : true;

      const commonReferenceAreaProps: CustomReferenceAreaProps = {
        id: event.id,
        label: `${event.marketName} Event`,
        value: marketEventStatusName[event.status],
        referenceArea: {
          strokeDasharray: "6 2",
          strokeWidth: 1,
          stroke: "var(--recharts-color-event-window-stroke)",
          fill: "var(--recharts-color-event-window-fill)",
        },
        additionalInfo: marketEventCategoryMap.get(event.category),
      };

      // ERCOT event with no endTime specified.
      if (!endTime && expectedEndTime) {
        referenceAreaProps.push({
          ...commonReferenceAreaProps,
          referenceArea: {
            ...commonReferenceAreaProps.referenceArea,
            shape: (
              <CustomEventWindow
                showLeftBorder={!eventStartsBeforeRange}
                showRightBorder={!eventEndsAfterRange}
              />
            ),
            x1: eventStartsBeforeRange ? undefined : startTime.getTime(),
            x2: expextedEndAfterRange ? undefined : expectedEndTime.getTime(),
          },
          description: `Event start: ${formatToTimeZone(
            startTime,
            `${dateFormat} ${timeFormat}`,
            {
              timeZone: options.timezone,
            }
          )}. ⟶ The end of the event is not yet announced. ERCOT will restore ERS resources at their discression.`,
        });
        if (!expextedEndAfterRange) {
          referenceAreaProps.push({
            ...commonReferenceAreaProps,
            id: `ghost-${event.id}`,
            referenceArea: {
              ...commonReferenceAreaProps.referenceArea,
              shape: (
                <CustomErcotEventAnimatedWindow
                  showBaselineGhostLine={event.readings.baseLine.records.length}
                  showTargetGhostLine={event.readings.targetLine.records.length}
                />
              ),
              x1: expectedEndTime.getTime(),
              x2: undefined,
            },
            hideInTooltip: true,
          });
        }
      } else if (endTime) {
        referenceAreaProps.push({
          ...commonReferenceAreaProps,
          referenceArea: {
            ...commonReferenceAreaProps.referenceArea,
            shape: (
              <CustomEventWindow
                showLeftBorder={!eventStartsBeforeRange}
                showRightBorder={!eventEndsAfterRange}
              />
            ),
            x1: eventStartsBeforeRange ? undefined : startTime.getTime(),
            x2: eventEndsAfterRange ? undefined : endTime.getTime(),
          },
          description: isSameDay(startTime, endTime)
            ? `${formatToTimeZone(startTime, `${dateFormat} ${timeFormat}`, {
                timeZone: options.timezone,
              })} - ${formatToTimeZone(endTime, timeFormat, {
                timeZone: options.timezone,
              })}`
            : `${formatToTimeZone(startTime, `${dateFormat} ${timeFormat}`, {
                timeZone: options.timezone,
              })} ⟶ ${formatToTimeZone(endTime, `${dateFormat} ${timeFormat}`, {
                timeZone: options.timezone,
              })}`,
        });
      }
    });

    return {
      referenceAreaProps,
    };
  }
  return {};
}

// returns how much hours is between the start-end dates; like '24h'
export function formatDateRangeintoNumberOfHours(
  startTime: Date,
  endTime: Date
) {
  return `${differenceInHours(endTime, startTime)} Hour`;
}

export function dateRangeFormatter(key: string, value: string) {
  if (key === "startDate" || key === "endDate") return new Date(value);
  else return value;
}

export function absoluteValueFormatter(value: number): number {
  return Math.abs(Math.round(value));
}

export function inverseValueFormatter(value: number): number {
  return (-1 * Math.round(value * 100)) / 100;
}

function formatSelectedDateRange(
  intervalType: IntervalType,
  startDate?: Date | string,
  endDate?: Date | string
): string | undefined {
  const start = typeof startDate === "string" ? new Date(startDate) : startDate;
  const end = typeof endDate === "string" ? new Date(endDate) : endDate;

  if (!start && !end && intervalType === IntervalType.All) {
    return "All data";
  }

  if (!start) {
    return undefined;
  }

  if (!end) {
    switch (intervalType) {
      case IntervalType.Day:
        return format(start, "MMM do, yyyy");
      case IntervalType.Month:
        return format(start, "MMMM yyyy");
      case IntervalType.Quarter:
        return format(start, "QQQ yyyy");
      case IntervalType.Year:
        return `Year ${format(start, "yyyy")}`;
      case IntervalType.Live:
        return format(start, "MMM do, yyyy");
      default:
        // in case of end date is not defined and the interval is not the one above => do not provide the interval information in the label
        return undefined;
    }
  }

  switch (intervalType) {
    case IntervalType.Day:
      return format(start, "MMMM do, yyyy");
    case IntervalType.Week:
      if (start.getFullYear() !== end.getFullYear()) {
        return `${format(start, "MMM do, yyyy")} - ${format(
          end,
          "MMM do, yyyy"
        )}`;
      }
      return `${format(start, "MMM do")} - ${format(end, "MMM do, yyyy")}`;
    case IntervalType.Month:
      return format(start, "MMMM yyyy");
    case IntervalType.Quarter:
      return format(start, "QQQ yyyy");
    case IntervalType.Year:
      return `Year ${format(start, "yyyy")}`;
    case IntervalType.Custom:
      return isSameYear(start, end)
        ? `${format(start, "MMM do")} - ${format(end, "MMM do, yyyy")}`
        : `${format(start, "MMM do, yyyy")} - ${format(end, "MMM do, yyyy")}`;
    case IntervalType.Live:
      return format(start, "MMM do, yyyy");
    case IntervalType.All:
      return isSameYear(start, end)
        ? `${format(start, "MMM do")} - ${format(
            end,
            "MMM do, yyyy"
          )} (All data)`
        : `${format(start, "MMM do, yyyy")} - ${format(
            end,
            "MMM do, yyyy"
          )} (All data)`;
  }
}

export function formatSubtitle(
  siteName: string,
  buildingName: string,
  intervalType: IntervalType,
  startDate?: Date | string,
  endDate?: Date | string
) {
  const name =
    siteName !== buildingName ? `${siteName} - ${buildingName}` : buildingName;

  const dateRangeString = formatSelectedDateRange(
    intervalType,
    startDate,
    endDate
  );

  return (
    <div>
      <span>{name}</span>
      {dateRangeString ? (
        <>
          &nbsp;
          {"/"}
          &nbsp;
          <time>{dateRangeString}</time>
        </>
      ) : null}
    </div>
  );
}

export function tooltipValueFormatter(value: number, locale?: string): string {
  let normalizedValue: number = value;

  const absoluteValue = Math.abs(value);

  if (absoluteValue >= 1) {
    normalizedValue = Math.round(value * 100) / 100; // 2-decimals
  } else if (absoluteValue < 1 && absoluteValue >= 0.1) {
    normalizedValue = Math.round(value * 10) / 10; // 1-decimal
  } else if (absoluteValue < 0.1 && absoluteValue >= 0.01) {
    normalizedValue = Math.round(value * 100) / 100; // 2-decimals
  } else if (absoluteValue < 0.01 && absoluteValue >= 0.001) {
    normalizedValue = Math.round(value * 1000) / 1000; // 3-decimals
  } else {
    normalizedValue = 0;
  }

  return Intl.NumberFormat(locale, {
    maximumFractionDigits: 3,
  }).format(normalizedValue);
}

export function defaultYAxisFormatter(value: any) {
  const valuesAsNumber = Number(value);
  if (isNumber(valuesAsNumber)) {
    // NOTE: This should solve overflowing issue for very large numbers which are unexpected from the user perspective
    return valuesAsNumber > 100_000
      ? `${valuesAsNumber / 1_000}k`
      : `${valuesAsNumber}`;
  }

  return value;
}
