import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import { init as initMatomo } from './lib/matomo/matomo';

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development' || !Boolean(process.env.REACT_APP_USE_MSW_MOCKS)) {
    return;
  }
 
  const { worker } = await import('./mocks/browser');
 
  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

enableMocking().then(() => {
  initMatomo();
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}).catch(e => console.log('error', e));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
