import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import L, { LatLng, LatLngBoundsExpression } from "leaflet";
import { useMapEvents, Rectangle, Polygon, CircleMarker } from "react-leaflet";
import MouseTooltip from "react-sticky-mouse-tooltip";

import { Marker } from "./index";
import { Button, ButtonGroup, Tooltip } from "@bphxd/ds-core-react";
import { uniqueId } from "lodash-es";
import { Add16, Check16, Remove16 } from "@bphxd/ds-core-react/lib/icons";

export type AreaSelectSettings = {
  selectAreaStartMousePointerTooltip: string;
  unselectAreaStartMousePointerTooltip: string;
  selectAreaStopMousePointerTooltip: string;
  unselectAreaStopMousePointerTooltip: string;
  selectAreaButtonTooltipText: string;
  unselectAreaButtonTooltipText: string;
  selectAreaButtonTooltipTextMobile: string;
  unselectAreaButtonTooltipTextMobile: string;
};

export interface AreaSelectProps {
  markers: Marker[];
  onSelectionChange: Function;
  areaSelectSettings?: AreaSelectSettings;
}

type AreaBoxState = {
  isDrawing: boolean;
  isSelecting: boolean;
  isUnselecting: boolean;
  cursorTooltipVisible: boolean;
  bounds: LatLng[];
};

const selectingColor = { color: "var(--map-color-dark-gray-700)" };
const deselectingColor = { color: "var(--map-color-dark-gray-700)" };

export default function AreaSelect(props: AreaSelectProps) {
  const {
    markers,
    onSelectionChange,
    areaSelectSettings = {
      selectAreaStartMousePointerTooltip:
        "Click and drag to start drawing an area.",
      unselectAreaStartMousePointerTooltip:
        "Click and drag to start drawing an area.",
      selectAreaStopMousePointerTooltip:
        "Release to select all buildings inside this area.",
      unselectAreaStopMousePointerTooltip:
        "Release to unselect all buildings inside this area.",
      selectAreaButtonTooltipText: "Select all buildings inside the area",
      unselectAreaButtonTooltipText: "Unselect all buildings inside the area",
      selectAreaButtonTooltipTextMobile:
        "Tap multiple places on the map to create an area and select all buildings inside it.",
      unselectAreaButtonTooltipTextMobile:
        "Tap multiple places on the map to create an area and unselect all buildings inside it.",
    },
  } = props;

  const {
    selectAreaStartMousePointerTooltip,
    unselectAreaStartMousePointerTooltip,
    selectAreaStopMousePointerTooltip,
    unselectAreaStopMousePointerTooltip,
    selectAreaButtonTooltipText,
    unselectAreaButtonTooltipText,
    selectAreaButtonTooltipTextMobile,
    unselectAreaButtonTooltipTextMobile,
  } = areaSelectSettings;

  const divRef = useRef<HTMLDivElement | null>(null);
  const [areaBox, setAreaBox] = useState<AreaBoxState>({
    isDrawing: false,
    isSelecting: false,
    isUnselecting: false,
    cursorTooltipVisible: false,
    bounds: [],
  });

  const {
    isDrawing,
    isSelecting,
    isUnselecting,
    bounds,
    cursorTooltipVisible,
  } = areaBox;
  const isSelectingOrDeselecting = isSelecting || isUnselecting;
  const isMobile = L.Browser.mobile;
  const tooltipDelay = { show: isMobile ? 0 : 400, hide: 0 };

  useEffect(() => {
    if (divRef.current) L.DomEvent.disableClickPropagation(divRef.current);
  }, []);

  const map = useMapEvents({
    mousedown(e) {
      if (isMobile || !isSelectingOrDeselecting) return;
      map.dragging.disable();
      setAreaBox((areaBox) => ({
        ...areaBox,
        isDrawing: true,
        bounds: [e.latlng, e.latlng],
      }));
    },
    mousemove(e) {
      if (isMobile || !isSelectingOrDeselecting || !isDrawing) return;
      setAreaBox((areaBox) => ({
        ...areaBox,
        bounds: [areaBox.bounds[0], e.latlng],
      }));
    },
    mouseup() {
      if (isMobile || !isSelectingOrDeselecting || !isDrawing) return;
      map.dragging.enable();
      const updatedMarkers =
        bounds.length > 1
          ? getUpdatedMarkersInArea(bounds, markers, false, isSelecting)
          : markers;
      if (onSelectionChange) onSelectionChange(updatedMarkers);
      setAreaBox((areaBox) => ({
        ...areaBox,
        isDrawing: false,
        bounds: [],
      }));
    },
    click(e) {
      if (!isMobile || !isSelectingOrDeselecting) return;
      setAreaBox((areaBox) => ({
        ...areaBox,
        bounds: [...areaBox.bounds, e.latlng],
      }));
    },
    mouseout() {
      setAreaBox((areaBox) => ({ ...areaBox, cursorTooltipVisible: false }));
    },
    mouseover() {
      setAreaBox((areaBox) => ({ ...areaBox, cursorTooltipVisible: true }));
    },
  });

  const handleSelectingToggle = useCallback(() => {
    if (!areaBox.isSelecting) {
      L.DomUtil.addClass(map.getContainer(), "crosshair-cursor-enabled");
    } else {
      L.DomUtil.removeClass(map.getContainer(), "crosshair-cursor-enabled");
    }
    setAreaBox((areaBox) => ({
      ...areaBox,
      isSelecting: !areaBox.isSelecting,
      isUnselecting: false,
    }));
  }, [areaBox.isSelecting, map]);

  const handleUnselectingToggle = useCallback(() => {
    if (!areaBox.isUnselecting) {
      L.DomUtil.addClass(map.getContainer(), "crosshair-cursor-enabled");
    } else {
      L.DomUtil.removeClass(map.getContainer(), "crosshair-cursor-enabled");
    }
    setAreaBox((areaBox) => ({
      ...areaBox,
      isUnselecting: !areaBox.isUnselecting,
      isSelecting: false,
    }));
  }, [areaBox.isUnselecting, map]);

  const handleMobileSubmit = useCallback(() => {
    const updatedMarkers =
      bounds.length > 1
        ? getUpdatedMarkersInArea(bounds, markers, true, isSelecting)
        : markers;
    onSelectionChange(updatedMarkers);
    setAreaBox((areaBox) => ({
      ...areaBox,
      isSelecting: false,
      isUnselecting: false,
      bounds: [],
    }));
  }, [markers, bounds, isSelecting, onSelectionChange]);

  const handleCancelAreaSelect = useCallback(() => {
    setAreaBox((areaBox) => ({
      ...areaBox,
      isSelecting: false,
      isUnselecting: false,
      bounds: [],
    }));
  }, []);

  const unselectAreaButtonId = useMemo(
    () => uniqueId("unselect-area-button"),
    []
  );
  const selectAreaButtonId = useMemo(() => uniqueId("select-area-button"), []);

  const isSomeMarkersSelected =
    markers.filter((item) => item.selected).length > 0;

  return (
    <>
      <div className={`ps-5 pe-5 d-flex justify-content-center`}>
        <div
          className="leaflet-control leaflet-bar leaflet-control-area-select"
          ref={divRef}
        >
          {isMobile && isSelectingOrDeselecting ? (
            <ButtonGroup className="bg-white" rounded="0" size="sm">
              <Button
                level="tertiary"
                onClick={handleCancelAreaSelect}
                Icon={Remove16}
              >
                Cancel
              </Button>
              <Button
                level="primary"
                onClick={handleMobileSubmit}
                disabled={bounds.length < 3}
                Icon={Check16}
              >
                {isSelecting ? "Select" : "Unselect"}
              </Button>
            </ButtonGroup>
          ) : (
            <>
              <ButtonGroup rounded="0" size="sm" className="bg-white">
                <Button
                  id={selectAreaButtonId}
                  level={isSelecting ? "primary" : "tertiary"}
                  onClick={handleSelectingToggle}
                  Icon={Add16}
                >
                  Select Area
                </Button>

                <Button
                  id={unselectAreaButtonId}
                  disabled={!isSomeMarkersSelected}
                  level={isUnselecting ? "primary" : "tertiary"}
                  onClick={handleUnselectingToggle}
                  Icon={Remove16}
                >
                  Unselect Area
                </Button>
              </ButtonGroup>

              <Tooltip target={unselectAreaButtonId} delay={tooltipDelay}>
                {isMobile
                  ? unselectAreaButtonTooltipTextMobile
                  : unselectAreaButtonTooltipText}
              </Tooltip>
              <Tooltip target={selectAreaButtonId}>
                {isMobile
                  ? selectAreaButtonTooltipTextMobile
                  : selectAreaButtonTooltipText}
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {!isMobile && (
        <MouseTooltip
          visible={isSelectingOrDeselecting && cursorTooltipVisible}
          offsetX={10}
          offsetY={8}
          style={{ zIndex: 9999, lineHeight: 1 }}
        >
          <small>
            {!isDrawing && isSelecting
              ? selectAreaStartMousePointerTooltip
              : !isDrawing && isUnselecting
              ? unselectAreaStartMousePointerTooltip
              : isDrawing && isSelecting
              ? selectAreaStopMousePointerTooltip
              : isDrawing && isUnselecting
              ? unselectAreaStopMousePointerTooltip
              : ""}
          </small>
        </MouseTooltip>
      )}
      {isSelectingOrDeselecting && isDrawing && bounds.length > 1 && (
        <Rectangle
          bounds={bounds as unknown as LatLngBoundsExpression}
          dashArray="4"
          pathOptions={isSelecting ? selectingColor : deselectingColor}
        />
      )}
      {isMobile && isSelectingOrDeselecting && bounds.length > 0 && (
        <>
          <Polygon
            positions={bounds}
            pathOptions={isSelecting ? selectingColor : deselectingColor}
          />
          {bounds.map((position, index) => (
            <CircleMarker
              key={index}
              center={position}
              radius={3}
              pathOptions={isSelecting ? selectingColor : deselectingColor}
            />
          ))}
        </>
      )}
    </>
  );
}

const isMarkerInsidePolygon = (marker: Marker, bounds: LatLng[]) => {
  const x = marker.lat,
    y = marker.long;

  let inside = false;
  for (let i = 0, j = bounds.length - 1; i < bounds.length; j = i++) {
    const xi = bounds[i].lat,
      yi = bounds[i].lng;
    const xj = bounds[j].lat,
      yj = bounds[j].lng;

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

const getUpdatedMarkersInArea = (
  bounds: LatLng[],
  markers: Marker[],
  isMobile: boolean,
  selected: boolean
) => {
  const LBounds = L.latLngBounds(bounds);
  return markers.map((marker: Marker) => {
    const isMarkerSelected = isMobile
      ? isMarkerInsidePolygon(marker, bounds)
      : LBounds.contains(L.latLng(marker.lat, marker.long));
    return isMarkerSelected ? { ...marker, selected } : marker;
  });
};
