import React, { createContext, Component, useContext } from "react";
import Keycloak from "keycloak-js";
import hoistNonReactStatic from "hoist-non-react-statics";

export const defaultDateFormat = "M/d/yyyy";
export const defaultTimeFormat = "h:mm a";

export type KeycloakProviderProps = React.PropsWithChildren<{
  url: Keycloak.KeycloakConfig["url"];
  clientId: Keycloak.KeycloakConfig["clientId"];
  enableLogging?: Keycloak.KeycloakInitOptions["enableLogging"];
  loginRedirectUri?: Keycloak.KeycloakLoginOptions["redirectUri"];
  logoutRedirectUri?: Keycloak.KeycloakLogoutOptions["redirectUri"];
  onLoad?: Keycloak.KeycloakInitOptions["onLoad"];
  pkceMethod?: Keycloak.KeycloakInitOptions["pkceMethod"];
  silentCheckSsoRedirectUri: Keycloak.KeycloakInitOptions["silentCheckSsoRedirectUri"];
  /**
   * Triggered when user is initially redirected to the app right after successful login
   */
  onLoginSuccess?: () => void;
  onAuthSuccess?: () => void;
  /**
   * Handler for all of the KC events
   */
  onKeycloakEvent?: (event: KeycloakEvent) => void;
  onLogout?: () => void;
  onTokenExpired?: () => void;
  /**
   * Fires when there was a successful auth or the token was refreshed successfully
   */
  onTokensUpdate?: (
    config: Pick<Keycloak.KeycloakInstance, "refreshToken" | "token"> & {
      idTokenParsed: CustomKeycloakIdTokenParsed | undefined;
    }
  ) => void;
  realm: Keycloak.KeycloakConfig["realm"];
  /**
   * If we should redirect to the KC login page right after logout
   */
  redirectToLoginOnAuthLogout?: boolean;
  responseMode?: Keycloak.KeycloakInitOptions["responseMode"];
  scope?: Keycloak.KeycloakLoginOptions["scope"];
  /**
   * Used for refreshing the token with the updateToken method (as the minValidity param)
   */
  tokenExpirationWindow?: number;
  /**
   * If we should get the user profile from the id token
   */
  useIdTokenForUserInfo?: boolean;
}>;

interface KeycloakProviderState {
  loading: boolean;
}

interface CustomKeycloakIdTokenParsed extends Keycloak.KeycloakTokenParsed {
  name?: string;
  email?: string;
  auth_time?: number;
}

interface UserInfo {
  id?: string;
  dateFormat?: string;
  email?: string;
  locale?: string;
  name?: string;
  picture?: string;
  timeFormat?: string;
}

interface KeycloakProviderContextValue {
  authenticating: boolean;
  authenticated: boolean | undefined;
  getTokens: KeycloakProvider["getTokens"];
  login: KeycloakProvider["login"];
  logout: KeycloakProvider["logout"];
  userInfo: UserInfo;
  tokenRefresh: KeycloakProvider["tokenRefresh"];
}

const authContext = createContext<KeycloakProviderContextValue>(
  {} as KeycloakProviderContextValue
);

const { Consumer: KeycloakConsumer, Provider } = authContext;
const useKeycloak = () => useContext(authContext);

enum KeycloakEvent {
  OnAuthError = "onAuthError",
  OnAuthLogout = "onAuthLogout",
  OnAuthSuccess = "onAuthSuccess",
  OnAuthRefreshError = "onAuthRefreshError",
  OnAuthRefreshSuccess = "onAuthRefreshSuccess",
  OnReady = "onReady",
  OnTokenExpired = "onTokenExpired",
}

type KeycloakEventPayload =
  | Keycloak.KeycloakError
  | (boolean | undefined)
  | undefined;

const keycloakEvents = [
  KeycloakEvent.OnReady,
  KeycloakEvent.OnAuthSuccess,
  KeycloakEvent.OnAuthError,
  KeycloakEvent.OnAuthRefreshSuccess,
  KeycloakEvent.OnAuthRefreshError,
  KeycloakEvent.OnAuthLogout,
  KeycloakEvent.OnTokenExpired,
];
class KeycloakProvider extends Component<
  KeycloakProviderProps,
  KeycloakProviderState
> {
  keycloak = this.createKeycloakInstance();
  userInfo: UserInfo = {};
  mounted = false;
  onLoginSuccessHandlerCalled = false;

  state: KeycloakProviderState = {
    loading: true,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  constructor(props: KeycloakProviderProps) {
    super(props);

    this.registerKeycloakListeners();
    this.initializeKeycloak();
  }

  get authenticated() {
    return this.keycloak.authenticated;
  }

  createKeycloakInstance(): Keycloak.KeycloakInstance {
    const { url, clientId, realm } = this.props;

    return new Keycloak({
      url,
      clientId,
      realm,
    });
  }

  initializeKeycloak() {
    const {
      onLoad = "check-sso",
      silentCheckSsoRedirectUri,
      pkceMethod = "S256",
      responseMode = "fragment",
      enableLogging = false,
    } = this.props;

    this.keycloak.init({
      onLoad,
      silentCheckSsoRedirectUri,
      pkceMethod,
      responseMode,
      enableLogging,
    });
  }

  registerKeycloakListeners() {
    keycloakEvents.forEach(
      (keycloakEvent) =>
        (this.keycloak[keycloakEvent] = this.handleKeycloakEvent(keycloakEvent))
    );
  }

  async fetchUserInfo(): Promise<UserInfo> {
    try {
      const userInfo: any = await this.keycloak.loadUserInfo();
      return {
        ...userInfo,
        id: userInfo.sub,
      };
    } catch (_error) {
      throw new Error("Failed to load user info.");
    }
  }

  async getUserInfoFromTokenAsync() {
    const { idTokenParsed }: { idTokenParsed?: CustomKeycloakIdTokenParsed } =
      this.keycloak;

    return await Promise.resolve({
      name: idTokenParsed?.name,
      email: idTokenParsed?.email,
      id: idTokenParsed?.sub,
      authTime: idTokenParsed?.auth_time,
      issuedAtTime: idTokenParsed?.iat,
    });
  }

  // Handler for all of the registered KC events
  handleKeycloakEvent =
    (event: KeycloakEvent) => (eventPayload?: KeycloakEventPayload) => {
      const {
        onKeycloakEvent,
        onLogout,
        redirectToLoginOnAuthLogout,
        tokenExpirationWindow = 1,
        onTokenExpired,
        onAuthSuccess,
        onLoginSuccess,
      } = this.props;

      if (event === "onReady") {
        if (!eventPayload && this.state.loading) {
          this.mounted && this.setState({ loading: false });
        }
      } else if (event === "onAuthSuccess") {
        if (typeof onAuthSuccess === "function") {
          onAuthSuccess();
        }
        this.handleTokensUpdate();

        this.loadUserInfo()
          .then((userInfo) => {
            this.userInfo = {
              // NOTE: formats temporarily hard-coded here until we'll have them coming from KC
              // US formats
              dateFormat: defaultDateFormat,
              timeFormat: defaultTimeFormat,
              locale: "en-US",
              // SK formats
              // dateFormat: "d.M.yyyy",
              // timeFormat: "HH:mm",
              // locale: "sk-SK",
              ...userInfo,
            };

            let hasUserRecentlyLoggedIn: boolean = false;

            if (
              typeof userInfo?.authTime === "number" &&
              typeof userInfo?.issuedAtTime === "number"
            ) {
              const timestampsDifference = Math.abs(
                userInfo.authTime - userInfo.issuedAtTime
              );
              if (timestampsDifference < 3) {
                hasUserRecentlyLoggedIn = true;
              }
            }

            if (
              typeof onLoginSuccess === "function" &&
              hasUserRecentlyLoggedIn &&
              !this.onLoginSuccessHandlerCalled
            ) {
              onLoginSuccess();
              this.onLoginSuccessHandlerCalled = true;
            }
          })
          .catch(() => {
            // TODO: implement error message later through onError handler
          })
          .finally(() => {
            this.mounted && this.setState({ loading: false });
          });
      } else if (event === "onAuthError") {
        // TODO: maybe send a log to the server if there will be a loggin service once
      } else if (event === "onTokenExpired") {
        if (typeof onTokenExpired === "function") onTokenExpired();
        this.tokenRefresh(tokenExpirationWindow);
      } else if (event === "onAuthRefreshSuccess") {
        this.handleTokensUpdate();
      } else if (event === "onAuthRefreshError") {
        this.purgeCredentials();
      } else if (event === "onAuthLogout") {
        // Will only be called if the session status iframe is enabled

        if (typeof onLogout === "function") {
          onLogout();
        }

        if (redirectToLoginOnAuthLogout) {
          this.login();
        }
      }

      if (typeof onKeycloakEvent === "function") {
        onKeycloakEvent(event);
      }
    };

  purgeCredentials() {
    this.userInfo = {};
    this.keycloak.clearToken();
  }

  handleTokensUpdate() {
    const { onTokensUpdate } = this.props;

    if (typeof onTokensUpdate === "function") {
      const { idTokenParsed, refreshToken, token } = this.keycloak;

      onTokensUpdate({ idTokenParsed, refreshToken, token });
    }
  }

  loadUserInfo = async () => {
    const { useIdTokenForUserInfo } = this.props;

    if (useIdTokenForUserInfo) {
      return await this.getUserInfoFromTokenAsync();
    }

    try {
      const userInfo: any = await this.keycloak.loadUserInfo();
      return {
        ...userInfo,
        id: userInfo.sub,
      };
    } catch (_error) {
      return await this.getUserInfoFromTokenAsync();
    }
  };

  login = (loginRedirectUri?: string) => {
    const { loginRedirectUri: loginRedirectUri_, scope } = this.props;

    try {
      if (!this.keycloak)
        throw new Error("Cant't perform login - Keycloak wasn't initialized.");

      const redirectUri = loginRedirectUri || loginRedirectUri_;

      this.keycloak.login({
        redirectUri,
        scope,
      });
    } catch (error) {
      throw new Error("Login failed");
    }
  };

  logout = async () => {
    const { logoutRedirectUri, onLogout } = this.props;

    try {
      if (!this.keycloak)
        throw new Error("Cant't perform logout - Keycloak wasn't initialized.");

      if (typeof onLogout === "function") {
        onLogout();
      }

      await this.keycloak.logout({
        redirectUri: logoutRedirectUri,
      });

      this.userInfo = {};
    } catch (error) {
      throw new Error("Logout failed.");
    }
  };

  getTokens = () => {
    const { idTokenParsed, refreshToken, token } = this.keycloak;

    return { idTokenParsed, refreshToken, token };
  };

  tokenRefresh = async (
    tokenExpirationWindow: number,
    propagateError = false
  ) => {
    try {
      const refreshed = await this.keycloak.updateToken(tokenExpirationWindow);

      if (refreshed) {
        return this.getTokens();
      }
    } catch (error) {
      this.purgeCredentials();
      if (propagateError) throw error;
    }
  };

  render() {
    const { children } = this.props;
    const { loading } = this.state;

    return (
      <Provider
        value={{
          authenticated: this.authenticated,
          authenticating: loading,
          userInfo: this.userInfo,
          login: this.login,
          logout: this.logout,
          getTokens: this.getTokens,
          tokenRefresh: this.tokenRefresh,
        }}
      >
        {children}
      </Provider>
    );
  }
}

function withKeycloak(WrappedComponent: React.ComponentType<any>) {
  return hoistNonReactStatic(
    (props) => (
      <KeycloakConsumer>
        {(value) => <WrappedComponent {...props} {...value} />}
      </KeycloakConsumer>
    ),
    WrappedComponent
  );
}

export { KeycloakConsumer, KeycloakProvider, useKeycloak, withKeycloak };
